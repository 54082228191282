import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import { siteMetadata } from '../../gatsby-config'
import PostCard from 'components/post-card'
import logo from '../../content/images/logo.svg'
import Meta from 'components/meta'
import Layout from 'components/layout'

const BlogIndex = ({ data, location }) => {
  const posts = get(data, 'remark.posts')
  return (
    <Layout location={location}>
      <Meta
        site={siteMetadata}
        title="遺品整理一括見積もりサービス｜優良企業のみ参加のeイーセイリ"
      />
      <header className="main-visual">
        <div className="container py-5 h-100">
          <div className="row align-items-center justify-content-center h-100">
            <div className="col-md-8">
              <p className="mb-0 text-white font-weight-bold text-shadow">
                ご実家の整理・空き家問題の解決策
              </p>
              <h1 className="bg-light p-3">
                <img
                  src={logo}
                  className="img-fliud mb-0"
                  alt="遺品整理一括見積もりサービス｜優良企業のみ参加のeイーセイリ"
                />
              </h1>
              <p className="bg-light p-3 h4 mb-0 text-justify text-md-center">
                日本の住環境を守り、育むために。
                <br />
                <span className="text-muted">
                  空き家対策・遺品整理・生前整理の専門家をご紹介しています。
                </span>
              </p>
            </div>
          </div>
        </div>
      </header>
      <section className="bg-light">
        <div className="container py-3">
          <p className="mb-4">
            eイーセイリは、手数料完全無料のマッチングサービス「遺品整理一括見積もり」を中心とした、空き家の整理や遺品整理サービスをご利用になる消費者の方に、価格と品質を両立させた安心の整理をご提供するためのサービスです。
          </p>

          <div className="card border border-danger mb-3">
            <div className="p-3">
              <p className="card-text font-weight-bold text-danger d-flex align-items-center">
                <span className="badge badge-danger mr-2">ご注意</span>
                eイーセイリをかたる営業にご注意ください。当社は営業電話や営業メール、営業訪問は一切致しておりません。
              </p>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="card position-relative hover-anime w-100">
                <div className="card-body">
                  <img src={logo} className="img-fliud p-3" />
                  <h2 className="h5 text-center">
                    <a className="stretched-link" href="/lp">
                      遺品整理一括見積もりサービス
                    </a>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container pt-4 my-3">
          <div className="card mb-0 h-100">
            <div className="card-header border-0 c-bg-light-pink font-weight-bold">
              <h2 className="h6 m-0 font-weight-bold">お知らせ</h2>
            </div>
            <div className="card-body c-info-card-scroll pt-2">
              <dl className="row align-items-top py-2 border-bottom m-0 overflow-auto">
                <dt className="col-md pt-1 pb-3 pb-md-0 px-0 text-md-center">
                  2020年 5月 18日
                </dt>
                <dd className="col-md-10 m-0 px-0">
                  <p className="mb-1 font-weight-bold">
                    <span className="badge badge-primary">新着</span>{' '}
                    <span className="font-weight-bold">
                      ウェブサイトをリニューアルしました。
                    </span>
                  </p>
                  <p>
                    お客様の利便性向上のため、サービスをリニューアルいたしました。
                  </p>
                </dd>
              </dl>
              <dl className="row align-items-top py-2 border-bottom m-0 overflow-auto">
                <dt className="col-md pt-1 pb-3 pb-md-0 px-0 text-md-center">
                  2020年 4月 11日
                </dt>
                <dd className="col-md-10 m-0 px-0">
                  <p className="mb-1 font-weight-bold">
                    <span className="badge badge-primary">新着</span>{' '}
                    <span className="font-weight-bold">臨時休業のお知らせ</span>
                  </p>
                  <p>
                    東京都緊急事態措置に伴う外出自粛要請をふまえ、令和2年4月11日(土)より令和2年5月6日(水)までサービスは
                    <span className="text-danger">臨時休業</span>
                    させて頂きます。
                  </p>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </section>
      <div className="container py-4">
        <div className="row">
          {posts.map(({ post }, i) => (
            <PostCard
              data={post}
              options={{
                isIndex: true,
              }}
              key={i}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        adsense
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
