import { Link } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'

import get from 'lodash/get'
import map from 'lodash/map'

import cardLogoImg from '../../../content/images/card-logo.png'

const PostCard = ({ data, options }) => {
  const {
    category,
    tags,
    description,
    title,
    path,
    date,
    image,
  } = data.frontmatter
  const { isIndex } = options
  const html = get(data, 'html')
  const isMore = isIndex && !!html.match('<!--more-->')
  const fluid = get(image, 'childImageSharp.fluid')

  return (
    <div className="col-md-6 mb-3" key={path}>
      <div className="card position-relative">
        <div className="card-header">
          <Link
            className="text-dark stretched-link"
            style={{ boxShadow: 'none' }}
            to={path}
          >
            <h2 className="h3">{title}</h2>
          </Link>
          <div className="d-flex align-items-center justify-content-between">
            <time dateTime={date}>{date}</time>
            <span>
              {Badges({ items: [category], primary: true })}
              {Badges({ items: tags })}
            </span>
          </div>
        </div>
        {fluid ? (
          <Img className="card-img-top" fluid={fluid} />
        ) : (
          <img className="card-img-top mb-0" src={cardLogoImg} />
        )}
        <div className="card-body">
          <p className="text-justify mb-0">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default PostCard

const Badges = ({ items, primary }) =>
  map(items, (item, i) => {
    return (
      <span
        className={`badge mx-1 ${
          primary ? 'badge-primary' : 'badge-secondary'
        }`}
        key={i}
      >
        {item}
      </span>
    )
  })
